// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    firebaseConfig: {
      apiKey: "AIzaSyCGBjBAY32pbHXomeQCgfPMJuEJF-yBAC8",
      authDomain: "building-mgmt.firebaseapp.com",
      projectId: "building-mgmt",
      storageBucket: "building-mgmt.appspot.com",
      messagingSenderId: "93034737699",
      appId: "1:93034737699:web:95309be5d7c5fefe890196",
      measurementId: "G-SC3REBQ844",
      vapidKey: 'BPqmkFgCu6FdazssGx8HxDhOrUf7ZxV7nPlALN2T3gLpWQPUCGnCLu-fa6HgQrXSMDgYeAoN9wduTdN3tdw0i6c'
    },
    app_version: '1.5.1 (alfa)',
    api_v: 'api/v1',
    imgURL: 'https://api-test.zazz.mx',
    SUPPORT_REST_WSPREFIX: 'https://srv.iomx.co/droid/message',
    AWS_REST_WSPREFIX: 'https://api-test.zazz.mx',
    TASK_REST_WSPREFIX: 'https://tasks-test.zazz.mx',
    RECIBOS_REST_WSPREFIX: 'https://receipt-test.zazz.mx',
    MAFIGUE_TOKEN_CC: '2xUhFSsrYF5prDqA',
    MAFIGUE_TOKEN_CA: 'DB259B805956A146',
    MAFIGUE_MERCHANT: 'mndaqdcv6r1egc0iwqvv',
    MAFIGUE_PK: 'pk_80bb3562ce454aca8e38cbb75ebcaefc',
    MAFIGUE_DEFAULT_CUS: '',
    AMRON_TOKEN_CC: '2xUhFSsrYF5prDqA',
    AMRON_TOKEN_CA: 'DB259B805956A146',
    AMRON_MERCHANT: 'mj4hm2lqliumfnokyjvo',    
    // AMRON_PK: 'pk_0544aaf0280a408b9501fd5e9e879df3',
    // AMRON_DEFAULT_CUS: '',
    AMRON_PK: 'pk_19c34873008f4c37b933925ee2273631',
    AMRON_DEFAULT_CUS: 'abe7zkle8ofrfaflofig',
    ANKER_TOKEN_CC: '2xUhFSsrYF5prDqA',
    ANKER_TOKEN_CA: 'DB259B805956A146',
    ANKER_MERCHANT: 'mbu12eghl7whfudnkoq4',
    ANKER_PK: 'pk_ba11d0981cfb4c70acf6dcafe3694ec7',
    ANKER_DEFAULT_CUS: '',
    JIMESA_TOKEN_CC: '2xUhFSsrYF5prDqA',
    JIMESA_TOKEN_CA: 'DB259B805956A146',
    JIMESA_MERCHANT: '',
    JIMESA_PK: '',
    JIMESA_DEFAULT_CUS: '',
    CENTURION_TOKEN_CC: '2xUhFSsrYF5prDqA',
    CENTURION_TOKEN_CA: 'DB259B805956A146',
    CENTURION_MERCHANT: '',
    CENTURION_PK: '',
    CENTURION_DEFAULT_CUS: '',
    MAPBOX_TOKEN: 'pk.eyJ1IjoicmljaG9sb25hIiwiYSI6ImNsM24wb3dwdDA5dmczZHBhb3BiYmRnZGYifQ.D0tt4iTHsGf4riM2cazCog',    
  };